import { MetaSwiper, SafariSwiper } from "./meta-settings.js";

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};
document.addEventListener("scroll", () => {
  if (scrollContainer().scrollTop > 50 || window.pageYOffset > 50) {
    header.classList.add("scrolled");
  } else if (scrollContainer().scrollTop == 0 || window.pageYOffset == 0) {
    header.classList.remove("scrolled");
  }
});

if (navigator.sayswho === 'Safari 12') {
  if (document.querySelector('.video-js')) {
    console.log('test');
    document.querySelector('.video-js').style.setProperty("padding-top", "35%", "important")
  }
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".open-more-menu").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".navbar .logo").toggleClass("active");
  $(".header").toggleClass("active");
  $(".desk-nav__list").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  if (scrollContainer().scrollTop == 0 || window.pageYOffset == 0) {
    // $(header).toggleClass("scrolled");
  }
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".header-close-wrapper__inner"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    $(".navbar .logo").removeClass("active");
    $(".desk-nav__list").removeClass("active");
    $(".open-more-menu").removeClass("active");
    $(".header").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".open-more-menu").click(menuToggle); //menu toggles

document.querySelector('.header .desk-nav .btn').addEventListener('click', () => {
  document.querySelector('.header .desk-nav__list').classList.remove('active')
  document.querySelector('.header .desk-nav__link.open-more-menu').classList.remove('active')
})


function btnCloseMenu (btn) {
  document.querySelector(btn).addEventListener('click', function() {
    document.querySelector(".menu-toggle").classList.remove("active");
    document.querySelector(".navbar .logo").classList.remove("active");
    document.querySelector(".header").classList.remove("active");
    document.querySelector(".header-close-wrapper").classList.remove("active");
    scrollLock.enablePageScroll();
  })
}

btnCloseMenu('.menu-btn')
btnCloseMenu('.menu-btn-contact')
// $(".header-close-wrapper").click(menuToggle); //menu toggles

// Menu Hover

function menuHover() {
  const navLi = document.querySelectorAll('.header__full ul li a')

  navLi.forEach(li => {
    li.style.opacity = 0.5
    li.addEventListener('mouseover', () => {
      for (let i = 0; i < navLi.length; i++) {
        navLi[i].style.opacity = 0.5
      }
      li.style.opacity = 1
    })
    li.addEventListener('mouseout', () => {
      for (let i = 0; i < navLi.length; i++) {
        navLi[i].style.opacity = 0.5
      }
    })

    // if (li.classList.contains('active')) {
    //   li.style.opacity = 1
    // } else {
    //   li.style.opacity = 0.5
    // }
  })
}

menuHover()

// BOLD Lottie init (Має бути в самому кінці файлу)
// lottie.loadAnimation({
//   container: document.getElementById(`bold-credits`),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/BOLD-white-logo.json",
// });

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const hero = document.getElementsByClassName('hero')[0];

console.log(isSafari)

if(hero && isSafari) {
  hero.classList.add('isSafari');
}



// ============================

// Featured Jobs

const featuredJobs = document.querySelector('.featured-jobs')

if (featuredJobs) {
  const featuredJobsSlider = new Swiper('.featured-jobs__slider', {
    speed: 400,
    spaceBetween: 25,
    // slidesPerView: 1.885,
    slidesPerView: 1,
    effect: 'creative',
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },

    creativeEffect: {
      limitProgress: 5,
      prev: {
        opacity: 0,
        translate: [0, 0, -100],
      },
      next: {
        translate: ['calc(100% + 20px)', 0, 0],
      },
    },
    navigation: {
      nextEl: ".featured-jobs .btn-arrow.next",
      prevEl: ".featured-jobs .btn-arrow.prev",
    },

    breakpoints: {
      550: {
        slidesPerView: 1,
      }
    }
  })
  SafariSwiper(featuredJobsSlider)

  const btnPrev = document.querySelector(".featured-jobs .btn-arrow.prev")
  const btnNext = document.querySelector(".featured-jobs .btn-arrow.next")
  
  if (btnPrev.classList.contains('swiper-button-disabled') && btnNext.classList.contains('swiper-button-disabled')) {
    btnPrev.style.display = 'none'
    btnNext.style.display = 'none'
  }
}


// News Slider

const newsSec = document.querySelectorAll('.news')

if (newsSec) {

  newsSec.forEach((sec, id) => {
    sec.classList.add(`news-${id}`)
    const secClass = sec.classList[sec.classList.length - 1]

    new Swiper(`.${secClass} .news__slider`, {
      speed: 400,
      spaceBetween: 20,
      slidesPerView: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,

      },
      navigation: {
        nextEl: `.${secClass} .btn-arrow.next`,
        prevEl: `.${secClass} .btn-arrow.prev`,
      },
  
      breakpoints: {
        1024: {
          spaceBetween: 60,
          slidesPerView: 3,
        },
        850: {
          spaceBetween: 60,
          slidesPerView: 3,
        },
        500: {
          slidesPerView: 2,
        }
      }
    })

    const btnPrev = document.querySelector(`.${secClass} .btn-arrow.prev`)
    const btnNext = document.querySelector(`.${secClass} .btn-arrow.next`)
    
    if (btnPrev.classList.contains('swiper-button-disabled') && btnNext.classList.contains('swiper-button-disabled')) {
      btnPrev.style.display = 'none'
      btnNext.style.display = 'none'
    }
  })
  
}

// Gallery

const gallerySec = document.querySelector('.gallery')

if (gallerySec) {
  const $grid = $('.gallery__grid').imagesLoaded(function() {
    $grid.masonry({
      itemSelector: '.gallery__img',
      columnWidth: '.gallery__img-width',
      percentPosition: true
    })
  })
}

const specialismsSec = document.querySelector('.specialisms')

if (specialismsSec) {
  $(window).resize(function () {
    $('.specialisms__grid .swiper-wraper').not('.slick-initialized').slick('resize');
  });

  $(window).on('orientationchange', function () {
    $('.specialisms__grid .swiper-wraper').not('.slick-initialized').slick('resize');
  });
  $('.specialisms__grid .swiper-wraper').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    slide: '.os-card',
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: $('.specialisms-arrows .prev'),
    nextArrow: $('.specialisms-arrows .next'),
    responsive: [
      {
        breakpoint: 651,
        settings: "unslick",
      },
      {
        breakpoint: 365,
        settings: {
          slidesToShow: 2,
        },
      }
    ]
  });
}



// Talk Swiper

const talkSec = document.querySelector('.talk__slider')

if (talkSec) {
  const select = $('.select-talk').select2({
    minimumResultsForSearch: -1
  })[0];

  function swiperButtonsFunc(swiper) {
    if (swiper.navigation.nextEl.classList.contains('swiper-button-disabled')) {
      swiper.navigation.nextEl.classList.add('hidden');
    } else {
      swiper.navigation.nextEl.classList.remove('hidden');
    }

    if (swiper.navigation.prevEl.classList.contains('swiper-button-disabled')) {
      swiper.navigation.prevEl.classList.add('hidden');
    } else {
      swiper.navigation.prevEl.classList.remove('hidden');
    }
  }

  const talkSlider = new Swiper('.talk__slider', {
    speed: 400,
    spaceBetween: 20,
    slidesPerView: 1,
    allowTouchMove: false,
    autoHeight: true,
    navigation: {
      nextEl: ".talk__btn .next",
      prevEl: ".talk__btn .prev"
    },

    on: {
      slideNextTransitionStart: function (swiper) {
        const index = swiper.realIndex;
        const slide = swiper.slides[index - 1];
        const input =
            slide.querySelector("input") || slide.querySelector("textarea");

        if (input && !input.value) {
          const field = input.getAttribute("data-name");
          noticeError(field + ' is required field!');
          talkSlider.slidePrev(0);
        }
      },
      slideChange: function (swiper) {
        swiperButtonsFunc(swiper);
      },
      init: function (swiper) {
        swiperButtonsFunc(swiper);
      }
    },
  })

  const message = talkSec.querySelector("textarea");
  const submitButton = document.querySelector(".talk__btn .next");
  const prevButton = document.querySelector(".talk__btn .prev");
  const sendBtn = document.querySelector('.talk__btn .can-send');

  if (message) {
    message.addEventListener("input", function (e) {
      if (message.value !== "") {
        sendBtn.classList.remove("hidden");
        console.log("can-send");
      } else {
        sendBtn.classList.add("hidden");
        console.log('can"t-send');
      }
    });
  }

  new Typed(".auto-type span", {
    strings: ["Orion Electrotech"],
    typeSpeed: 150,
    backSpeed: 150,
    loop: true
  })


  /*const talkInput = document.querySelector('.talk__right .talk-name')

  document.querySelector('.talk__btn.first .btn').addEventListener('click', function(e) {
    e.preventDefault();

    if (talkInput.value === '') {
      document.querySelector('.talk-name').style.display = 'block'
    } else {
      document.querySelector('.talk-name').style.display = 'none'
    }
  })


  function valid (e) {
    if(e.target.value !== '') {
      document.querySelector('.talk__btn.first .btn').addEventListener('click', function(e) {
        e.preventDefault();

        this.style.display = 'none'
        document.querySelector('.talk__slider').classList.add('active')
        document.querySelector('.talk__btn.talk-nav').classList.add('active')
      })
      document.querySelector('.talk__btn.first .btn').removeAttribute("disabled")
    } else {
      document.querySelector('.talk__btn.first .btn').setAttribute("disabled", "")
    }
  }

  talkInput.addEventListener('input', (e) => {
    valid(e)
  })
  talkInput.addEventListener('change', (e) => {
    valid(e)
  })*/
}

// Select
window.addEventListener("load", () => {
  $('.select').select2({
    minimumResultsForSearch: -1
  });

  $('select.input').select2({
    minimumResultsForSearch: -1,
  });
  
  $('.sort-jobs').select2({
    minimumResultsForSearch: -1,
  });
});

const OurServicesSec = document.querySelector('.our-services')

if (OurServicesSec) {
  const OurServicesSlider = new Swiper('.our-services__top', {
    speed: 400,
    spaceBetween: 0,
    slidesPerView: 'auto',
    allowTouchMove: true,
    // autoHeight: true,
    freeMode: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },

    navigation: {
      nextEl: ".our-services__next-btn",
      prevEl: ".our-services__prev-btn"
    }
  })
  function ifFirstSlide () {
    if (OurServicesSlider.activeIndex === 0) {
      document.querySelector('.our-services__prev').style.display = 'none'
    } else {
      document.querySelector('.our-services__prev').style.display = 'flex'
    }
  }
  ifFirstSlide()
  OurServicesSlider.on('slideChange', function () {
    ifFirstSlide()
    setTimeout(() => {
      if (document.querySelector('.our-services__next-btn').classList.contains('swiper-button-disabled')) {
        console.log(true);
        document.querySelector('.our-services__next').style.display = 'none'
      } else {
        console.log(false);
        document.querySelector('.our-services__next').style.display = 'flex'
      }
    }, 10);
  });

  var x = window.matchMedia("(max-width: 700px)")

  if (!x.matches) {
    OurServicesSlider.destroy()
  }

  SafariSwiper(OurServicesSlider)


}

const benefitsSec = document.querySelectorAll('.benefits-slider')

if (benefitsSec) {
  benefitsSec.forEach((sec, id) => {
    sec.classList.add(`benefits-slider-${id}`)
    const secClass = sec.classList[sec.classList.length - 1]
   
    new Swiper(`.${secClass} .benefits-slider__inner`, {
      speed: 400,
      spaceBetween: 30,
      slidesPerView: 1,
      navigation: {
        nextEl: `.${secClass} .benefits-slider__btns .next`,
        prevEl: `.${secClass} .benefits-slider__btns .prev`,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      breakpoints: {
        1200: {
          spaceBetween: 160,
          slidesPerView: 5,
        },
        900: {
          spaceBetween: 100,
          slidesPerView: 4,
        },
        700: {
          spaceBetween: 70,
          slidesPerView: 4,
        },
        540: {
          spaceBetween: 70,
          slidesPerView: 3,
        },
        370: {
          spaceBetween: 40,
          slidesPerView: 2,
        },
      }
    })

    if (document.querySelector(`.benefits-slider-${id} .benefits-slider__btns .prev`)) {
      if (document.querySelector(`.benefits-slider-${id} .benefits-slider__btns .prev`).classList.contains('swiper-button-disabled') && document.querySelector(`.benefits-slider-${id} .benefits-slider__btns .next`).classList.contains('swiper-button-disabled')) {
          document.querySelector(`.benefits-slider-${id} .benefits-slider__btns .prev`).style.display = 'none'
          document.querySelector(`.benefits-slider-${id} .benefits-slider__btns .next`).style.display = 'none'
      }
    }
  })
}

const videoSec = document.querySelector('.video-js')

if (videoSec) {
  const player = videojs('vid1');
  player.currentTime(1);
  player.aspectRatio('20:9');
  player.fluid(true);

  const videoPT = setInterval(() => {
    document.querySelector(".video-js .vjs-tech").style.setProperty('padding-top', '0px', 'important');
  }, 200)

  setTimeout(() => {
    clearInterval(videoPT)
  }, 10000)
}


const teamSec = document.querySelector('.people__slider')

if (teamSec) {
  const teamSlider = new Swiper(teamSec, {
    speed: 400,
    spaceBetween: 50,
    slidesPerView: 1,
    // loopAdditionalSlides: 9,
    // loop: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".people-nav .btn-arrow.next",
      prevEl: ".people-nav .btn-arrow.prev",
    },
    breakpoints: {
      1024: {
        spaceBetween: 68,
        slidesPerView: 3,
      },
      500: {
        spaceBetween: 50,
        slidesPerView: 2,
      }
    }
  })

  const btnPrev = document.querySelector(`.people-nav .btn-arrow.prev`)
  const btnNext = document.querySelector(`.people-nav .btn-arrow.next`)
  
  if (btnPrev.classList.contains('swiper-button-disabled') && btnNext.classList.contains('swiper-button-disabled')) {
    btnPrev.style.display = 'none'
    btnNext.style.display = 'none'
  }
}

const sectorsSupp = document.querySelector('.sectors-supp')

if (sectorsSupp) {
  const btns = document.querySelectorAll('.sectors-supp__left button')
  const content = document.querySelectorAll('.sectors-supp__content')

  btns.forEach((btn, id) => {
    btn.addEventListener('click', () => {
      for (let i = 0; i < btns.length; i++) {
        btns[i].classList.remove('active')
      }
      btn.classList.add('active')

      for (let i = 0; i < content.length; i++) {
        if (id === +content[i].dataset.id) {
          for (let j = 0; j < content.length; j++) {
            content[j].classList.remove('active')
          }
          content[i].classList.add('active')
        }
      }
    })
  })
}

function accordion() {
  const headAccordion = document.querySelectorAll('.head_accordion')

  if (headAccordion) {
    headAccordion.forEach(item => {
      const link = item.querySelector('.head_accordion__main')
      const content = item.querySelector('.head_accordion__content')
      let flag = false
      link.addEventListener('click', function(e) {
        flag = !flag
        e.preventDefault()
        if (flag) {
          this.classList.add('active')
          content.style.maxHeight = content.scrollHeight + 'px'
        } else {
          this.classList.remove('active')
          content.style.maxHeight = '0px'
        }
      })
    })
  }
}

accordion()

const blogSec = document.querySelector('.news-sec__grid')

if (blogSec && blogSec.classList.contains('swiper')) {
  var blogSlider = new Swiper('.news-sec__grid.swiper', {
    speed: 400,
    spaceBetween: 20,
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      nextEl: `.news-sec.under-hero .btn-arrow.next`,
      prevEl: `.news-sec.under-hero .btn-arrow.prev`,
    },

    breakpoints: {
      1024: {
        spaceBetween: 60,
        slidesPerView: 3,
      },
      850: {
        spaceBetween: 60,
        slidesPerView: 3,
      },
      500: {
        slidesPerView: 2,
      }
    }
  })

  const btnPrev = document.querySelector(`.news-sec.under-hero .btn-arrow.prev`)
  const btnNext = document.querySelector(`.news-sec.under-hero .btn-arrow.next`)
  
  if (btnPrev.classList.contains('swiper-button-disabled') && btnNext.classList.contains('swiper-button-disabled')) {
    btnPrev.style.display = 'none'
    btnNext.style.display = 'none'
  }
}

const benefitsThumbsSlider = document.getElementsByClassName(`benefits-thumbs-slider`)[0];

if (benefitsThumbsSlider) {

  const thumbsSwiperLogoLeft = new Swiper(".benefits-thumbs-slider-logo", {
    spaceBetween: 0,
    slidesPerView: 1,
    grid: false,
    allowTouchMove: false,
    breakpoints: {
      651: {
        direction: 'vertical',
        slidesPerView: 2,
        grid: {
          rows: 1,
          column: 1,
        },
      }
    }
  });

  const thumbsSwiperInfoLeft = new Swiper(".benefits-thumbs-slider-info", {
    spaceBetween: 20,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    // Navigation
    navigation: {
      prevEl: ".benefits-thumbs-slider-info__btns .btn-arrow.prev",
      nextEl: ".benefits-thumbs-slider-info__btns .btn-arrow.next",
    },

    breakpoints: {
      651: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      }
    },

    thumbs: {
      swiper: thumbsSwiperLogoLeft,
    },
  });

  thumbsSwiperLogoLeft.slides.forEach((elem, index) => {
    elem.addEventListener("click", (e) => {
      thumbsSwiperInfoLeft.slideTo(index);
    });
  });
}

const benefitsSliderMicro = document.getElementsByClassName('benefits-slider-micro')[0];
if(benefitsSliderMicro) {
  const benefitsSwiperMicro = new Swiper(".benefits-slider-micro", {
    spaceBetween: 20,
    autoplay: {
      delay: 4000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    autoHeight: true,
    // Navigation
    navigation: {
      prevEl: ".benefits-slider-micro .btn-arrow.prev",
      nextEl: ".benefits-slider-micro .btn-arrow.next",
    },
  });
}

const teamsSlider = document.getElementsByClassName('team-card-list')[0];
if(teamsSlider) {
  const teamsSlidesArr = teamsSlider.querySelectorAll('.swiper-slide');

  if(teamsSlidesArr.length > 1) {
    const teamsSwiper = new Swiper(".team-card-list", {
      spaceBetween: 14,
      slidesPerView: 1,
      autoplay: {
        delay: 4000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      // Navigation
      navigation: {
        prevEl: ".team-card-list__buttons .btn-arrow.prev",
        nextEl: ".team-card-list__buttons .btn-arrow.next",
      },

      breakpoints: {
        651: {
          spaceBetween: 14,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 24,
          slidesPerView: 2,
        }
      },
    });
  } else {
    const infoText = teamsSlidesArr[0].querySelector('.team-card__info-text');
    const infoBlock = teamsSlidesArr[0].querySelector('.team-card__info');
    infoBlock.appendChild(infoText);
    document.querySelector('.team-card-list__buttons').style.display = 'none';
  }
  teamsSlider.style.opacity = '1';
}

const historySlider = document.getElementsByClassName(`history-slider`)[0];

if (historySlider) {

  const historySliderLength = historySlider.querySelectorAll('.swiper-slide').length - 1;

  const historySwiper = new Swiper(".history-slider", {
    direction: "vertical",
    slidesPerView: 3,
    spaceBetween: 30,
    /*autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },*/
    mousewheel: {
      forceToAxis: true,
      sensitivity: 1,
      releaseOnEdges: true,
    },
    centeredSlides: true,
    slideActiveClass: "swiper-slide-active",
    initialSlide: 0,
  });

  historySwiper.on("activeIndexChange", function (e) {
    if (historySliderLength === e.activeIndex) {
      historySlider.classList.add("last-slide");
    } else {
      historySlider.classList.remove("last-slide");
    }
  });
}

const clientsSaySlider = document.getElementsByClassName(`clients-say-slider`)[0];

if (clientsSaySlider) {

  const clientsSaySwiper = new Swiper(".clients-say-slider", {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".clients-say-slider .clients-say-slider-pagination",
      clickable: true,
      bulletElement: "li",
      bulletClass: "clients-bullet",
      bulletActiveClass: "active",
    },

    /*breakpoints: {
      651: {
        slidesPerView: 'auto',
        grid: {
          rows: 3,
          column: 1,
        },
      }
    }*/
  });
}

if (window.location.href.includes('microsite')) {
  document.querySelector('.menu-btn-contact').setAttribute('href', '/#footer_form_anchor')
  document.querySelector('.menu-btn').setAttribute('href', '/#footer_form_anchor')
}

addEventListener("load", () => {
  if (window.location.href.includes('blog') || window.location.href.includes('job')) {
    document.querySelectorAll('.job-detail__content p').forEach(p => {
      p.removeAttribute('style')
    })
    document.querySelectorAll('.job-detail__content li').forEach(li => {
      li.removeAttribute('style')
    })
    document.querySelectorAll('.job-detail__content ul').forEach(ul => {
      ul.removeAttribute('style')
    })
  }
  if (window.location.href.includes('job') || window.location.href.includes('blog')) {
    document.querySelector('.burger').classList.add('white')
  }
});

if (document.querySelector('.sec-about__videos')) {
  $('.sec-about__videos').slick({
    dots: false,
    arrows: true,
    infinite: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: $('.sec-about__videos__btns .prev'),
    nextArrow: $('.sec-about__videos__btns .next')
  });

  function videoFunc(el) {
    const allIframes = document.querySelectorAll(el)

    let controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'settings', 'fullscreen'];
    if(window.innerWidth <= 551) {
      controls = ['play-large', 'airplay', 'fullscreen'];
    }
    
    Array.from(allIframes).forEach(video => {
      const player = new Plyr(video, {
        controls: controls,
        quality: { default: 1080, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240] },
        defaultQuality: 'highres',
      });
      window.player = player;
  
      player.on('play', (event) => {
        $('.sec-about__videos').slick('slickPause');

        if(window.innerWidth <= 551) {
          player.fullscreen.enter();
        }

      });
      player.on('pause', (event) => {
        $('.sec-about__videos').slick('slickPlay');
      });

      player.on('exitfullscreen', (event) => {
        if(window.innerWidth <= 551) {
          player.stop();
        }
      });
      
      $(".sec-about__videos").on("beforeChange", function(event, slick) {
        $('.sec-about__videos').slick('slickPlay');
        player.pause();
      })
    })
  }

  videoFunc('.video-plyr-player')
  /*videoFunc('#vid1')*/
}
const videoPlayerArr = document.querySelectorAll('.video-player');
const players = [];

for (let i = 0; i < videoPlayerArr.length; i++) {
  const player = new Plyr(videoPlayerArr[i], {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'settings', 'fullscreen'],
    quality: { default: 1080, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240] },
    defaultQuality: 'highres',
  });
  players.push(player);
}

if (window.innerWidth >= 1024) {
  setTimeout(function () {
    const plyrArr = document.querySelectorAll(".plyr");
    console.log(plyrArr)
    for (let j = 0; j < plyrArr.length; j++) {
      plyrArr[j].classList.add("plyr-hide-controls");

      plyrArr[j].addEventListener("mouseenter", () => {
        plyrArr[j].classList.remove("plyr-hide-controls");
      });
    }
  }, 300);
}

const galleryPhotoSlider = document.getElementsByClassName(`gallery-photo-slider`)[0];
const galleryPhotoSliderNav = document.getElementsByClassName('gallery-photo-slider-nav')[0];

if (galleryPhotoSlider) {
  let activeSlide = 0;
  // const toggleSlide = galleryPhotoSlider.querySelector('.toggle-slide');

  const galleryPhotoSwiper = new Swiper(".gallery-photo-slider", {
    slidesPerView: 1,
    spaceBetween: 18,
    slideToClickedSlide: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },

    /*on: {
      init: function () {
        galleryHeight();
      },
    },*/

    breakpoints: {
      691: {
        spaceBetween: 30,
      }
    }
  });

  galleryPhotoSwiper.on('slideChange', function () {
    for (let i = 0; i < players.length; i++) {
      activeSlide = galleryPhotoSwiper.activeIndex;
      navSlidesChecked(galleryPhotoSliderNav, activeSlide);
    }
  });

  if(galleryPhotoSliderNav) {

    const navSlides = galleryPhotoSliderNav.querySelectorAll('.swiper-slide');
    if(navSlides.length <= 3) {
      galleryPhotoSliderNav.style.overflow = 'visible';
      galleryPhotoSliderNav.parentElement.style.overflow = 'visible';

      if(navSlides.length === 3) {
        galleryPhotoSliderNav.classList.add('with-3-items');
      }
    }

    navSlidesChecked(galleryPhotoSliderNav, activeSlide);

    const galleryPhotoNavSwiper = new Swiper(".gallery-photo-slider-nav", {
      slidesPerView: 1,
      spaceBetween: 14,
      slideToClickedSlide: true,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 1,
        releaseOnEdges: true,
      },
      scrollbar: {
        el: ".gallery-photo-slider-scrollbar",
        hide: false,
        draggable: true,
      },

      breakpoints: {
        374: {
          spaceBetween: 14,
          slidesPerView: 1.6,
        },
        521: {
          spaceBetween: 14,
          slidesPerView: 2,
        },
        691: {
          spaceBetween: 28,
          direction: 'vertical',
          slidesPerView: 3,
          grid: {
            rows: 1,
            column: 1,
          },
        },
      }
    });

    galleryPhotoNavSwiper.slides.forEach((elem, index) => {
      elem.addEventListener("click", (e) => {
        galleryPhotoSwiper.slideTo(index);
      });
    });
  }
}

const galleryVideoSlider = document.getElementsByClassName(`gallery-video-slider`)[0];
const galleryVideoSliderNav = document.getElementsByClassName('gallery-video-slider-nav')[0];

if (galleryVideoSlider) {
  let activeSlide = 0;

  const galleryVideoSwiper = new Swiper(".gallery-video-slider", {
    slidesPerView: 1,
    spaceBetween: 18,
    slideToClickedSlide: true,
    threshold: 5,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },

    /*on: {
      init: function () {
        galleryHeight();
      },
    },*/

    breakpoints: {
      691: {
        spaceBetween: 30,
      }
    }
  });

  galleryVideoSwiper.on('slideChange', function () {
    for (let i = 0; i < players.length; i++) {
      players[i].pause();
      activeSlide = galleryVideoSwiper.activeIndex;
      navSlidesChecked(galleryVideoSliderNav, activeSlide);
    }
  });

  if(galleryVideoSliderNav) {

    const navSlides = galleryVideoSliderNav.querySelectorAll('.swiper-slide');
    if(navSlides.length <= 3) {
      galleryVideoSliderNav.style.overflow = 'visible';
      galleryVideoSliderNav.parentElement.style.overflow = 'visible';

      if(navSlides.length === 3) {
        galleryVideoSliderNav.classList.add('with-3-items');
      }
    }

    if (document.getElementsByClassName('gallery-slider-nav__video')[0]) {
      const galleryVideoSliderNavYtArr = document.querySelectorAll('.gallery-slider-nav__video');

      galleryVideoSliderNavYtArr.forEach((videoBlock) => {
        const styleAttributeValue = videoBlock.getAttribute('style');
        const backgroundImageValue = styleAttributeValue.match(/background-image:\s*url\(['"]([^'"]+)['"]\)/i);

        if (backgroundImageValue && backgroundImageValue.length > 1) {
          const backgroundImageUrl = backgroundImageValue[1];

          let videoId = null;

          // Extract video ID from both types of YouTube URLs
          const matchEmbed = backgroundImageUrl.match(/\/embed\/([^/?]+)/);
          const matchWatch = backgroundImageUrl.match(/\/watch\?v=([^&]+)/);

          if (matchEmbed) {
            videoId = matchEmbed[1];
          } else if (matchWatch) {
            videoId = matchWatch[1];
          }

          if (videoId) {
            const newBackgroundImageUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
            videoBlock.style.backgroundImage = `url("${newBackgroundImageUrl}")`;
          }
        }
      });
    }

    navSlidesChecked(galleryVideoSliderNav, activeSlide);

    const galleryVideoNavSwiper = new Swiper(".gallery-video-slider-nav", {
      slidesPerView: 1,
      spaceBetween: 14,
      slideToClickedSlide: true,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 1,
        releaseOnEdges: true,
      },
      scrollbar: {
        el: ".gallery-video-slider-scrollbar",
        hide: false,
        draggable: true,
      },

      breakpoints: {
        374: {
          spaceBetween: 14,
          slidesPerView: 1.6,
        },
        521: {
          spaceBetween: 14,
          slidesPerView: 2,
        },
        691: {
          spaceBetween: 28,
          direction: 'vertical',
          slidesPerView: 3,
          grid: {
            rows: 1,
            column: 1,
          },
        },
      }
    });

    galleryVideoNavSwiper.slides.forEach((elem, index) => {
      elem.addEventListener("click", (e) => {
        galleryVideoSwiper.slideTo(index);
      });
    });
  }
}

function navSlidesChecked(gallerySliderNav, activeSlide) {
  const gallerySlidesNavArr = gallerySliderNav.querySelectorAll('.swiper-slide');
  gallerySlidesNavArr.forEach((slide, i) => {
    if(i === activeSlide) {
      slide.style.display = 'none';
    } else {
      slide.style.display = 'block';
    }
  })

}

const testiSliderSec = document.querySelector('.testi-sec__slider')

if (testiSliderSec) {
  const testiSlider = new Swiper(testiSliderSec, {
    speed: 400,
    spaceBetween: 50,
    slidesPerView: 1,
    navigation: {
      nextEl: ".testi-sec__slider .testi-btns button.next",
      prevEl: ".testi-sec__slider .testi-btns button.prev",
    },
  })

  if (testiSlider.slides.length <= 1) {
    document.querySelector('.testi-sec__slider .testi-btns').style.display = 'none'
  }
}

const gallerySlider = document.getElementsByClassName('gallery-section__slider')[0];
/*function galleryHeight() {
  const gallerySliderArr = document.querySelectorAll('.gallery-section__slider');
  setTimeout(function () {
    const gallerySliderHeight = gallerySlider.clientHeight;
    console.log(gallerySliderHeight)
    for (let i = 0; i < gallerySliderArr.length; i++) {
      gallerySliderArr[i].style.minHeight = `${gallerySliderHeight}px`;
    }
  }, 400);
}*/

if (gallerySlider) {
  const gallerySliderArr = document.querySelectorAll('.gallery-section__slider');
  // const gallerySliderNavArr = document.querySelectorAll('.gallery-section__slider-buttons');
  const gallerySliderButtonsArr = document.querySelectorAll('.gallery-section__btn-toggle');

  for (const elem of gallerySliderButtonsArr) {
    elem.addEventListener('click', () => {
      if (elem.classList.contains('active')) return;
      document.querySelector('.gallery-section__slider.active').classList.remove('active');
      // document.querySelector('.gallery-section__slider-buttons.active').classList.remove('active');
      document.querySelector('.gallery-section__btn-toggle.active').classList.remove('active');
      elem.classList.add('active');
      const i = Array.from(gallerySliderButtonsArr).indexOf(elem);
      // gallerySliderNavArr[i].classList.add('active');
      gallerySliderArr[i].classList.add('active');
    })
  }
}
